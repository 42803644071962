import Text from "components/atoms/Text";
import PropTypes from "prop-types";
import { LinkIcon } from "components/atoms/Link";
import Image from "next/image";
import {Typography} from "@mui/material";
import { useMediaQuery } from "react-responsive";

export default function ImagenTextoPosicion({
  imgPosition,
  imgUri,
  text,
  buttonUri,
  textButton,
  linkColor,
  classname,
  title,
  mobileSrc,
  tabletSrc,
  desktopSrc,
  desktopXlSrc,
  desktop2xlSrc,
  nuevoCliente,
  vendedor,
  clientes
}) {

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isDesktopXl = useMediaQuery({ minWidth: 1281, maxWidth: 1440 });
  const isDesktop2xl = useMediaQuery({ minWidth: 1441 });

  return (
    <div
      className={
        "relative 2xl:ml-32 2xl:mr-32 z-50 lg:flex lg:relative lg:h-[385px] " +
        classname
      }
    >
      {imgPosition == "derecha" ? (
        <>
          <div
            style={{ display: "flex" }}
            className="lg:h-96 lg:w-1/2 lg:absolute right-0"
          >
            {imgUri ? (
              <>
              {isMobile && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={mobileSrc.blurURL}
                  src={mobileSrc.href}
                  alt={imgUri}
                  width={420}
                  height={237}
                />
              )}
              {isTablet && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={tabletSrc.blurURL}
                  src={tabletSrc.href}
                  alt={imgUri}
                  width={1024}
                  height={576}
                />
              )}
              {isDesktop && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={desktopSrc.blurURL}
                  src={desktopSrc.href}
                  alt={imgUri}
                  width={1280}
                  height={720}
                />
              )}
              {isDesktopXl && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={desktopXlSrc.blurURL}
                  src={desktopXlSrc.href}
                  alt={imgUri}
                  width={1440}
                  height={810}
                />
              )}
              {isDesktop2xl && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={desktop2xlSrc.blurURL}
                  src={desktop2xlSrc.href}
                  alt={imgUri}
                  width={1920}
                  height={1080}
                />
              )}
            </>
            ) : (
              <Image
                loading="lazy"
                // placeholder="blur"
                // blurDataURL={tabletSrc}
                width="953"
                height="384"
                src="/img/no-image-available.svg"
                alt="no image"
              />
            )}
          </div>
          <div
            className="landings lg:w-[55%] pt-4 lg:pt-12 pb-4 lg:pb-12 pl-4 lg:pl-8 pr-4 lg:pr-8 flex justify-center text-justify flex-col lg:absolute left-0 top-[10%] lg:h-72"
            style={{
              borderBottom: `solid 3px ${linkColor}`,
              backgroundColor: "#FFFFFFD9",
            }}
          >
            {title ? <Typography variant='h3' textAlign={'start'} fontSize={{ xs: "1.5rem", lg: "2rem" }} fontWeight={600} my={2}>{ title }</Typography> : null}
            <Text text={text} />
            {buttonUri ? (
              <div className="flex justify-center mt-8 lg:ml-auto text-xs lg:text-lg">
                {vendedor || clientes || nuevoCliente ? (
                  <LinkIcon
                    url={buttonUri}
                    textLink={textButton}
                    colorLink={linkColor}
                    tab="_self"
                  />
                ) : (
                  <LinkIcon
                    url={buttonUri}
                    textLink={textButton}
                    colorLink={linkColor}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{ display: "flex" }}
            className="lg:h-96 lg:w-1/2 lg:absolute left-0"
          >
            {imgUri ? (
              <>
              {isMobile && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={mobileSrc.blurURL}
                  src={mobileSrc.href}
                  alt={imgUri}
                  width={420}
                  height={237}
                />
              )}
              {isTablet && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={tabletSrc.blurURL}
                  src={tabletSrc.href}
                  alt={imgUri}
                  width={1024}
                  height={576}
                />
              )}
              {isDesktop && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={desktopSrc.blurURL}
                  src={desktopSrc.href}
                  alt={imgUri}
                  width={1280}
                  height={720}
                />
              )}
              {isDesktopXl && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={desktopXlSrc.blurURL}
                  src={desktopXlSrc.href}
                  alt={imgUri}
                  width={1440}
                  height={810}
                />
              )}
              {isDesktop2xl && (
                <Image
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={desktop2xlSrc.blurURL}
                  src={desktop2xlSrc.href}
                  alt={imgUri}
                  width={1920}
                  height={1080}
                />
              )}
            </>
            ) : (
              <Image
                loading="lazy"
                placeholder="blur"
                blurDataURL='/img/no-image-available.svg'
                width="953"
                height="384"
                src="/img/no-image-available.svg"
                alt="no image"
              />
            )}
          </div>
          <div
            className="landings lg:w-[55%] pt-4 lg:pt-12 pb-4 lg:pb-12 pl-4 lg:pl-8 pr-4 lg:pr-8 flex justify-center text-justify flex-col lg:absolute right-0 top-[10%] lg:h-72"
            style={{
              borderBottom: `solid 3px ${linkColor}`,
              backgroundColor: "#FFFFFFD9",
            }}
          >
            { title ? <Typography variant='h3' textAlign={'start'} fontSize={{xs: '1.5rem', md:'2rem'}} fontWeight={600} my={2}>{ title }</Typography> : null}
            <Text text={text} />
            {buttonUri ? (
              <div className="flex justify-center mt-8 lg:ml-auto text-xs lg:text-lg">
                {vendedor || clientes || nuevoCliente ? (
                  <LinkIcon
                    url={buttonUri}
                    textLink={textButton}
                    colorLink={linkColor}
                    tab="_self"
                  />
                ) : (
                  <LinkIcon
                    url={buttonUri}
                    textLink={textButton}
                    colorLink={linkColor}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}

ImagenTextoPosicion.propTypes = {
  imgPosition: PropTypes.string.isRequired,
  imgUri: PropTypes.string,
  text: PropTypes.string,
  buttonUri: PropTypes.string,
  textButton: PropTypes.string,
  linkColor: PropTypes.string,
  mobileSrc: PropTypes.object,
  tabletSrc: PropTypes.object,
  desktopSrc: PropTypes.object.isRequired,
  desktopXlSrc: PropTypes.object,
  desktop2xlSrc: PropTypes.object,
};
